const common = {
  namespaced: true,

  state: {},
  mutations: {},
  actions: {
    // set preference based Exchange
    setOrderTypeCommon({ state, commit, dispatch, rootState }, payload) {
      var InstName = "instrument_type";
      var Exchange = "exch";
      var nse_OrderType = ["Regular", "Bracket", "AMO"];
      var bse_OrderType = ["Regular", "AMO"];
      var mcx_OrderType = ["Regular", "Cover", "Bracket", "AMO"];
      var nfo_Fut_OrderType = ["Regular", "Cover", "Bracket", "AMO"];
      var nfo_Option_OrderType = ["Regular", "AMO"];
      var nfo_Option_index_OrderType = ["Regular", "Cover", "Bracket", "AMO"];
      var cds_Or_bcd_OrderType = ["Regular", "Cover"];
      var nco_Commidity_OrderType = ["Regular", "Bracket"];
      var bco_Commidity_OrderType = ["Regular"];
      var bfo_OrderType = ["Regular", "AMO"];

      if (payload[Exchange] == "NSE") {
        commit("orderWindow/setOrderTypeList", nse_OrderType, { root: true });
      }
      if (payload[Exchange] == "BSE") {
        commit("orderWindow/setOrderTypeList", bse_OrderType, { root: true });
      }
      if (payload[Exchange] == "MCX") {
        commit("orderWindow/setOrderTypeList", mcx_OrderType, { root: true });
      }
      if (payload[Exchange] == "CDS" || payload[Exchange] == "BCD") {
        commit("orderWindow/setOrderTypeList", cds_Or_bcd_OrderType, {
          root: true,
        });
      }
      if (payload[Exchange] == "NCO") {
        commit("orderWindow/setOrderTypeList", nco_Commidity_OrderType, {
          root: true,
        });
      }
      if (payload[Exchange] == "BCO") {
        commit("orderWindow/setOrderTypeList", bco_Commidity_OrderType, {
          root: true,
        });
      }

      if (payload[Exchange] == "NFO" && payload[InstName] == "OPTSTK") {
        commit("orderWindow/setOrderTypeList", nfo_Option_OrderType, {
          root: true,
        });
      }
      if (payload[Exchange] == "NFO" && payload[InstName] == "OPTIDX") {
        commit("orderWindow/setOrderTypeList", nfo_Option_index_OrderType, {
          root: true,
        });
      }

      if (
        payload[Exchange] == "NFO" &&
        (payload[InstName] == "FUTIDX" || payload[InstName] == "FUTSTK")
      ) {
        commit("orderWindow/setOrderTypeList", nfo_Fut_OrderType, {
          root: true,
        });
      }
      if (payload[Exchange] == "BFO") {
        commit("orderWindow/setOrderTypeList", bfo_OrderType, { root: true });
      }
      let page = rootState.orderWindow.currentPage;
      var orderTypeList = rootState.orderWindow.orderTypeList;
      let isModify = rootState.orderWindow.modifyOrder;
      let isRepeat = rootState.orderWindow.isRepeat;
      let pageData = rootState.orderWindow.selectedData;
      let orderTab = 0;
      let preference;

      if (page == "mkWatch" || (page == "orders" && !isModify && !isRepeat)) {
        preference = JSON.parse(localStorage.getItem("orderPreference"));
        if (preference.variety) {
          var tabName;
          preference["variety"] == "regular"
            ? (tabName = "Regular")
            : preference["variety"] == "cover"
            ? (tabName = "Cover")
            : preference["variety"] == "bracket"
            ? (tabName = "Bracket")
            : preference["variety"] == "amo"
            ? (tabName = "AMO")
            : "";
          orderTab = orderTypeList.indexOf(tabName);
          if (Number(orderTab) == -1) {
            orderTab = 0;
            preference["variety"] = "regular";
            localStorage.setItem("orderPreference", JSON.stringify(preference));
          }
        }
      } else if (page == "holdings" || page == "basket") {
        orderTab = 0;
      } else if (page == "positions") {
        var checkTab = pageData.orderType;
        orderTab = orderTypeList.indexOf(checkTab);
      } else if (page == "orders" && (isModify || isRepeat)) {
        var checkTab = pageData.orderType;

        orderTab = orderTypeList.indexOf(checkTab);
      }
      commit("orderWindow/setOrderTab", orderTab, { root: true });
      dispatch("setProdTypeCommon");
    },

    // set ProdType preference based Exchange
    setProdTypeCommon(
      { state, commit, dispatch, rootState, rootGetters },
      payload
    ) {
      var orderData =
        rootState.orderWindow.contractOrderData[
          rootState.orderWindow.selectItem
        ];
      var InstName = "instrument_type";
      var orderTypeList = rootState.orderWindow.orderTypeList;
      var selectedTab = rootState.orderWindow.orderTab;
      var exchange = rootState.orderWindow.currentExchange;
      let page = rootState.orderWindow.currentPage;
      let pageData = rootState.orderWindow.selectedData;
      let isModify = rootState.orderWindow.modifyOrder;
      let isBasketSSo = rootState.orderWindow.isSsobasket;
      let isRepeat = rootState.orderWindow.isRepeat;
      let isGtt = rootState.orderWindow.isGtt;
      let isInstantOrder = rootState.orderWindow.isInstantOrder;
      if (orderTypeList[selectedTab] == "Cover") {
        commit("orderWindow/setProductItems", ["MIS"], { root: true });
        commit(
          "orderWindow/setPriceItems",
          rootState.orderWindow.coverPriceItems,
          { root: true }
        );
      } else if (orderTypeList[selectedTab] == "Bracket") {
        commit("orderWindow/setProductItems", ["MIS"], { root: true });
        exchange == "NCO"
          ? commit("orderWindow/setPriceItems", ["L", "MKT", "SL"], {
              root: true,
            })
          : commit(
              "orderWindow/setPriceItems",
              rootState.orderWindow.bracketPriceItems,
              { root: true }
            );
      } else if (orderTypeList[selectedTab] == "AMO") {
        exchange == "NSE" || exchange == "BSE"
          ? commit("orderWindow/setProductItems", ["MIS", "CNC"], {
              root: true,
            })
          : commit("orderWindow/setProductItems", ["MIS", "NRML"], {
              root: true,
            });
        commit("orderWindow/setPriceItems", ["L", "MKT", "SL"], { root: true });
      } else if (orderTypeList[selectedTab] == "Regular") {
        exchange == "NSE" || exchange == "BSE"
          ? commit("orderWindow/setProductItems", ["MIS", "CNC"], {
              root: true,
            })
          : commit("orderWindow/setProductItems", ["MIS", "NRML"], {
              root: true,
            });

        exchange == "MCX" ||
        exchange == "NSE" ||
        exchange == "BSE" ||
        exchange == "NFO" ||
        exchange == "BFO"
          ? commit("orderWindow/setPriceItems", ["L", "MKT", "SL", "SL-M"], {
              root: true,
            })
          : ((exchange == "NFO" || exchange == "BFO") &&
              orderData[InstName] == "OPTIDX") ||
            exchange == "CDS" ||
            exchange == "BCD"
          ? commit("orderWindow/setPriceItems", ["L", "MKT", "SL"], {
              root: true,
            })
          : commit("orderWindow/setPriceItems", ["L", "SL"], { root: true });
        // for only bfo of urgent
        exchange == "BFO"
          ? commit("orderWindow/setPriceItems", ["L", "MKT", "SL"], {
              root: true,
            })
          : "";
      }

      orderTypeList[selectedTab] == "Regular" ||
      orderTypeList[selectedTab] == "AMO"
        ? commit("orderWindow/setValidityItems", ["DAY", "IOC"], { root: true })
        : commit("orderWindow/setValidityItems", ["DAY"], { root: true });

      if (isBasketSSo && payload) {
        commit("orderWindow/setPriceType", pageData.orderType, { root: true });
        commit("orderWindow/setProdType", pageData.product, { root: true });
        commit("orderWindow/setValidityType", pageData.validity, {
          root: true,
        });
      }
      if (!payload) {
        if (page == "holdings") {
          commit("orderWindow/setPriceType", "MKT", { root: true });
          commit("orderWindow/setProdType", "CNC", { root: true });
          commit("orderWindow/setValidityType", "DAY", { root: true });
        }
        if (page == "positions") {
          commit("orderWindow/setPriceType", "MKT", { root: true });
          commit("orderWindow/setProdType", pageData.product, { root: true });
          commit("orderWindow/setValidityType", "DAY", { root: true });
        }
        if (page == "mkWatch" || (page == "orders" && !isModify && !isRepeat)) {
          let priceType;
          let prodType;
          let valityType;
          let preference = JSON.parse(localStorage.getItem("orderPreference"));
          preference.orderType
            ? (priceType = preference.orderType)
            : (priceType = "L");
          preference.product
            ? (prodType = preference.product)
            : (prodType = "MIS");
          preference.validity
            ? (valityType = preference.validity)
            : (valityType = "DAY");

          commit("orderWindow/setPriceType", priceType, { root: true });
          commit("orderWindow/setProdType", prodType, { root: true });
          commit("orderWindow/setValidityType", valityType, { root: true });
        }
        if (page == "orders" && (isModify || isRepeat)) {
          if (
            orderTypeList[selectedTab] == "Bracket" &&
            pageData.product == "BO" &&
            pageData.priceType == "SL-M" &&
            !rootState.orderWindow.showPriceItems.includes(pageData.priceType)
          ) {
            var array = rootState.orderWindow.showPriceItems;
            array.push("SL-M");
            commit("orderWindow/setPriceItems", array, { root: true });
          }
          commit("orderWindow/setPriceType", pageData.priceType, {
            root: true,
          });
          commit("orderWindow/setProdType", pageData.product, { root: true });
          commit("orderWindow/setValidityType", pageData.ret, {
            root: true,
          });
        }
        if (page == "basket") {
          commit("orderWindow/setPriceType", "L", { root: true });
          commit("orderWindow/setProdType", "MIS", { root: true });
          commit("orderWindow/setValidityType", "DAY", { root: true });
        }

        if (page == "chart") {
          var PrcType = pageData.type ? pageData.type : "L";
          commit("orderWindow/setPriceType", PrcType, { root: true });
          commit("orderWindow/setProdType", "MIS", { root: true });
          commit("orderWindow/setValidityType", "DAY", { root: true });
        }
      }
      if (
        !rootState.orderWindow.showproductItems.includes(
          rootState.orderWindow.prodType
        )
      ) {
        rootState.orderWindow.showproductItems.includes("NRML")
          ? commit("orderWindow/setProdType", "NRML", { root: true })
          : "";
        rootState.orderWindow.showproductItems.includes("MTF")
          ? commit("orderWindow/setProdType", "MTF", { root: true })
          : "";
        rootState.orderWindow.showproductItems.includes("CNC")
          ? commit("orderWindow/setProdType", "CNC", { root: true })
          : "";
        rootState.orderWindow.showproductItems.includes("MIS")
          ? commit("orderWindow/setProdType", "MIS", { root: true })
          : "";
      }

      if (
        !rootState.orderWindow.showPriceItems.includes(
          rootState.orderWindow.priceType
        )
      ) {
        rootState.orderWindow.showPriceItems.includes("MKT")
          ? commit("orderWindow/setPriceType", "MKT", { root: true })
          : "";
        rootState.orderWindow.showPriceItems.includes("SL")
          ? commit("orderWindow/setPriceType", "SL", { root: true })
          : "";
        rootState.orderWindow.showPriceItems.includes("SL-M")
          ? commit("orderWindow/setPriceType", "SL-M", { root: true })
          : "";
        rootState.orderWindow.showPriceItems.includes("L")
          ? commit("orderWindow/setPriceType", "L", { root: true })
          : "";
      }
      commit(
        "orderWindow/changeOrderType",
        rootGetters["orderWindow/getOrderTabValue"],
        { root: true }
      );

      if ((!payload || (payload && isBasketSSo)) && !isInstantOrder && !isGtt) {
        setTimeout(() => {
          dispatch("orderWindow/getMarginAvailable", "", { root: true });
        }, 350);

        dispatch("orderWindow/getBrokerageData", "", { root: true });
      }
      if (isInstantOrder) {
        let json = {
          qty: rootState.orderWindow.quantity,
          complexity: "regular",
          pcode: "mis",
          priceType: "MKT",
          price: 0,
          trgPrice: "0",
          stopLoss: 0,
          trailStopLoss: 0,
          discQty: 0,
          validity: rootState.orderWindow.validityType,
          targetPrice: 0,
        };
        dispatch("orderWindow/placeOrder", json, { root: true });
      }
    },

    setCookie(name, value) {
      // Encode value in order to escape semicolons, commas, and whitespace
      var cookie = name + "=" + encodeURIComponent(value);

      var now = new Date();
      var date = now.getDate();
      let tempMonth = now.getMonth() + 1;
      var month = tempMonth > 9 ? tempMonth : `0${tempMonth}`;
      var year = now.getFullYear();
      var expireTime = `${date}/${month}/${year}/23:59:59`;
      now.setDate(expireTime);
      /* Sets the max-age attribute so that the cookie expires
            after the specified number of days */
      cookie += "; max-age=" + now + ";SameSite=Lax";

      document.cookie = cookie;
    },
    getCookie(name) {
      // Split cookie string and get all individual name=value pairs in an array
      var cookieArr = document.cookie.split(";");

      // Loop through the array elements
      for (var i = 0; i < cookieArr.length; i++) {
        var cookiePair = cookieArr[i].split("=");

        /* Removing whitespace at the beginning of the cookie name
                and compare it with the given string */
        if (name == cookiePair[0].trim()) {
          // Decode the cookie value and return
          return decodeURIComponent(cookiePair[1]);
        }
      }

      // Return null if not found
      return null;
    },
    delete_cookie(name) {
      document.cookie =
        name + "=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;";
    },
    setInitialValue(element, where) {
      let tickerData = window.tickers;
      let exch = element.exchange
        ? element.exchange
        : element.ex
        ? element.ex
        : element.exch;
      if (
        tickerData &&
        element.token &&
        tickerData[element.token] &&
        tickerData[element.token].e &&
        exch == tickerData[element.token].e
      ) {
        let responseFeed = tickerData[element.token];
        if (where == "mkwatch") {
          element.ltp = responseFeed?.lp ? responseFeed?.lp : element.ltp;
          element.volume = responseFeed?.v ? responseFeed?.v : element.volume;
          element.PerChange = responseFeed?.pc
            ? responseFeed?.pc
            : element.PerChange;
          element.pdc = responseFeed?.c ? responseFeed?.c : element.pdc;
          element.Change = responseFeed?.lp
            ? responseFeed.lp - element.pdc
            : element.Change;
          element.l = responseFeed?.l ? responseFeed.l : element.l;
          element.h = responseFeed?.h ? responseFeed.h : element.h;
          element.o = responseFeed?.o ? responseFeed.o : element.o;
          element.c = responseFeed?.c ? responseFeed.c : element.c;

          element.openrate = responseFeed?.o
            ? responseFeed.o
            : element.openrate;
          element.highrate = responseFeed?.h
            ? responseFeed.h
            : element.highrate;
          element.lowrate = responseFeed?.l ? responseFeed.l : element.lowrate;
          element.previouscloserate = responseFeed?.c
            ? responseFeed.c
            : element.previouscloserate;
          element.BPrice1 = responseFeed?.bp1
            ? responseFeed.bp1
            : element.BPrice1;
          element.BOrders1 = !!responseFeed?.bo1
            ? responseFeed.bo1
            : element.BOrders1;
          element.BQty1 = !!responseFeed?.bq1
            ? responseFeed.bq1
            : element.BQty1;
          element.BPrice2 = responseFeed?.bp2
            ? responseFeed.bp2
            : element.BPrice2;
          element.BOrders2 = responseFeed?.bo2
            ? responseFeed.bo2
            : element.BOrders2;
          element.BQty2 = responseFeed?.bq2 ? responseFeed.bq2 : element.BQty2;
          element.BPrice3 = responseFeed?.bp3
            ? responseFeed.bp3
            : element.BPrice3;
          element.BOrders3 = responseFeed?.bo3
            ? responseFeed.bo3
            : element.BOrders3;
          element.BQty3 = responseFeed?.bq3 ? responseFeed.bq3 : element.BQty3;
          element.BPrice4 = responseFeed?.bp4
            ? responseFeed.bp4
            : element.BPrice4;
          element.BOrders4 = responseFeed?.bo4
            ? responseFeed.bo4
            : element.BOrders4;
          element.BQty4 = responseFeed?.bq4 ? responseFeed.bq4 : element.BQty4;
          element.BPrice5 = responseFeed?.bp5
            ? responseFeed.bp5
            : element.BPrice5;
          element.BOrders5 = responseFeed?.bo5
            ? responseFeed.bo5
            : element.BOrders5;
          element.BQty5 = responseFeed?.bq5 ? responseFeed.bq5 : element.BQty5;
          element.totalbuyqty = responseFeed?.tbq
            ? responseFeed.tbq
            : element.totalbuyqty;
          element.SPrice1 = responseFeed?.sp1
            ? responseFeed.sp1
            : element.SPrice1;
          element.SOrders1 = responseFeed?.so1
            ? responseFeed.so1
            : element.SOrders1;
          element.SQty1 = responseFeed?.sq1 ? responseFeed.sq1 : element.SQty1;
          element.SPrice2 = responseFeed?.sp2
            ? responseFeed.sp2
            : element.SPrice2;
          element.SOrders2 = responseFeed?.so2
            ? responseFeed.so2
            : element.SOrders2;
          element.SQty2 = responseFeed?.sq2 ? responseFeed.sq2 : element.SQty2;
          element.SPrice3 = responseFeed?.sp3
            ? responseFeed.sp3
            : element.SPrice3;
          element.SOrders3 = responseFeed?.so3
            ? responseFeed.so3
            : element.SOrders3;
          element.SQty3 = responseFeed?.sq3 ? responseFeed.sq3 : element.SQty3;
          element.SPrice4 = responseFeed?.sp4
            ? responseFeed.sp4
            : element.SPrice4;
          element.SOrders4 = responseFeed?.so4
            ? responseFeed.so4
            : element.SOrders4;
          element.SQty4 = responseFeed?.sq4 ? responseFeed.sq4 : element.SQty4;
          element.SPrice5 = responseFeed?.sp5
            ? responseFeed.sp5
            : element.SPrice5;
          element.SOrders5 = responseFeed?.so5
            ? responseFeed.so5
            : element.SOrders5;
          element.SQty5 = responseFeed?.sq5 ? responseFeed.sq5 : element.SQty5;
          element.totalsellqty = responseFeed?.tsq
            ? responseFeed.tsq
            : element.totalsellqty;
          element.volume = responseFeed?.v ? responseFeed.v : element.volume;
          element.lasttradedqty = responseFeed?.ltq
            ? responseFeed.ltq
            : element.lasttradedqty;
          element.lc = responseFeed?.lc ? responseFeed.lc : element.lc;
          element.lasttradedtime = responseFeed?.ltt
            ? responseFeed.ltt
            : element.lasttradedtime;
          element.weightedavg = responseFeed?.ap
            ? responseFeed.ap
            : element.weightedavg;
          element.openinterest = responseFeed?.oi
            ? responseFeed.oi
            : element.openinterest;
          element.uc = responseFeed?.uc ? responseFeed.uc : element.uc;
        }
        if (where == "holdings" && responseFeed.lp) {
          element.ltp = responseFeed.lp;
        }
        if (where == "orders" && responseFeed.lp) {
          element.ltp = responseFeed.lp;
        }
        if (where == "positions" && responseFeed.lp) {
          element.ltp = responseFeed.lp;
        }
        if (where == "OrderWindow" && responseFeed.lp) {
          element.ltp = responseFeed.lp;
        }
        if (where == "Basket" && responseFeed.lp) {
          element.ltp = responseFeed.lp;
        }
        return element;
      } else {
        return false;
      }
    },
    isSafariBrowser() {
      var isSafari =
        navigator.vendor &&
        navigator.vendor.indexOf("Apple") > -1 &&
        navigator.userAgent &&
        navigator.userAgent.indexOf("CriOS") == -1 &&
        navigator.userAgent.indexOf("FxiOS") == -1;
      return isSafari;
    },
  },
};
export default common;
